export default class Criteria {
  public key: string
  public name: string
  public selected: boolean
  public group: string
  public separator: boolean

  public constructor(key: string, name: string, group: string, separator: boolean = false) {
    this.key = key
    this.name = name
    this.selected = false
    this.group = group
    this.separator = separator
  }
}
