




















































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import EditSidebar from '@/components/price/manager/EditSidebar.vue'
import EditFormCriteria from '@/components/price/manager/EditFormCriteria.vue'
import EditFormCalculation from '@/components/price/manager/EditFormCalculation.vue'
import Criteria from '@/models/price/manager/Criteria'
import { vxm } from '@/store'
import Margin from '@/models/price/manager/Margin'
import { DateTime } from 'luxon'
import MarginType from '@/models/price/manager/MarginType'
import BasePrice from '@/models/price/manager/BasePrice'

@Component({
  components: {
    EditSidebar,
    EditFormCriteria,
    EditFormCalculation,
  },
})
export default class Edit extends Vue {
  private criteria = [
    new Criteria('productId', 'Product ID', 'productId'),
    new Criteria('productEanCode', 'EAN code', 'productId', true),
    new Criteria('productBrandId', 'Brand', 'productAttributes'),
    new Criteria('productModelId', 'Model', 'productAttributes'),
    new Criteria('productRimBrandId', 'Rim brand', 'productAttributes'),
    new Criteria('productClassId', 'Season', 'productAttributes'),
    new Criteria('productVehicleTypes', 'Vehicle type', 'productAttributes'),
    new Criteria('properties', 'Properties', 'productAttributes'),
    new Criteria('productWidth', 'Width', 'productAttributes'),
    new Criteria('productHeight', 'Aspect ratio', 'productAttributes'),
    new Criteria('productDiameter', 'Diameter', 'productAttributes'),
    new Criteria('productCondition', 'Condition', 'productAttributes', true),
    new Criteria('supplierId', 'Supplier', 'supplier'),
    new Criteria('supplierProductGroupTag', 'Group tag', 'supplier', true),
    new Criteria('period', 'Period', 'period'),
  ]

  private loading = false
  private saving = false
  private savedText = ''
  private margin: Margin = null
  private canUsePriceBonus = false
  private productTypes
  private vehicleTypes
  private suppliers
  private priceLists
  private productConditions

  private get title() {
    return this.$t('Edit price rule')
  }

  private get id() {
    return this.$route.params.id || null
  }

  private get isNew() {
    return !this.$route.params.id && this.$route.params.marginType
  }

  private async created() {
    this.loading = true

    try {
      const responses = await Promise.all([
        this.$axios.get('/v4/site/price/lists'),
        this.$axios.get('/v4/site/suppliers/connected'),
        this.$axios.get('/v4/site/products/enums/conditions'),
        this.$axios.get('/v4/site/products/types?perPage=500&includeShop=1'),
        this.$axios.get('/v4/site/products/enums/vehicle-types'),
      ])

      this.priceLists = responses[0].data.data
      this.suppliers = responses[1].data.data
      this.productConditions = responses[2].data.data
      this.productTypes = responses[3].data.data
      this.vehicleTypes = responses[4].data.data
    } catch (err) {
      await vxm.alert.onAxiosError(err, 'Could not load data')
    }

    if (this.isNew) {
      this.initNewMargin()
      this.loading = false
    } else {
      this.loadMargin(() => {
        this.loading = false
      })
    }

    this.canUsePriceBonus = vxm.user.hasFeatureUsePriceBonus
  }

  private initNewMargin() {
    this.margin = new Margin({
      id: null,
      type: this.$route.params.marginType,
      basePrice: BasePrice.CostPrice,
      productTypeId: 1,
    })
    switch (this.margin.type) {
      case MarginType.CustomerDiscount:
        this.margin.customerId = parseInt(this.$route.params.referenceId)
        break
      case MarginType.Discount:
        this.margin.supplierId = parseInt(this.$route.params.referenceId)
        break
    }
    this.setCriteriasSelected()
  }

  private loadMargin(cb = null) {
    this.$axios
      .get('/v4/site/price/manager/any/any/' + this.id)
      .then((response) => {
        const data = response.data
        this.margin = new Margin(data.margin)
        this.setCriteriasSelected()
        if (cb) {
          cb()
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not load margin')
        this.loading = false
      })
  }

  private setCriteriaSelected(key: string, selected: boolean): void {
    for (let i = 0; i < this.criteria.length; i++) {
      const c = this.criteria[i]
      if (c.key === key) {
        c.selected = selected
        break
      }
    }
  }

  private setCriteriasSelected(): void {
    let isPeriodSelected = false
    let isPropertiesSelected = false
    for (const key in this.margin) {
      const value = this.margin[key]
      let isSelected = value !== null && value !== undefined && value !== ''
      if (key === 'productVehicleTypes' && isSelected) {
        isSelected = value.length > 0
      }
      this.setCriteriaSelected(key, isSelected)
      if ((key === 'startDate' || key === 'expiryDate') && isSelected) {
        isPeriodSelected = true
      }
      if (
        (key === 'productIsRunflat' ||
          key === 'productIsEnforced' ||
          key === 'productIsSpiked' ||
          key === 'productIsSpikeable') &&
        isSelected
      ) {
        isPropertiesSelected = true
      }
    }
    this.setCriteriaSelected('period', isPeriodSelected)
    this.setCriteriaSelected('properties', isPropertiesSelected)
  }

  private getSavePayload() {
    const payload = Object.assign({}, this.margin)
    for (let i = 0; i < this.criteria.length; i++) {
      const c = this.criteria[i]
      if (c.selected) {
        continue
      }
      switch (c.key) {
        case 'period':
          payload.startDate = null
          payload.expiryDate = null
          break
        case 'properties':
          payload.productIsRunflat = null
          payload.productIsEnforced = null
          payload.productIsSpiked = null
          payload.productIsSpikeable = null
          break
        default:
          payload[c.key] = null
      }
    }
    return payload
  }

  private save(): void {
    this.saving = true
    let url = '/v4/site/price/manager/' + this.margin.type + '/any'
    if (!this.isNew) {
      url += '/' + this.margin.id
    }
    this.$axios
      .post(url, this.getSavePayload())
      .then((response) => {
        const time = DateTime.now().toFormat('HH:mm:ss')
        this.savedText = this.$t('Saved at') + ' ' + time
        this.saving = false
        this.margin.name = response.data.data.name
        this.margin.id = response.data.data.id
        this.back()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed saving price rule')
        this.saving = false
      })
  }

  private cancel(): void {
    this.back()
  }

  private isSaveDisabled(): boolean {
    return this.loading || this.margin.isSupplierNetPrice
  }

  private back(): void {
    this.$router.back()
  }
}
