import MarginType from '@/models/price/manager/MarginType'

export default class Margin {
  public id: number
  public type: string
  public supplierId: number
  public shopId: number
  public productTypeId: number
  public productBrandId: number
  public productRimBrandId: number
  public productModelId: number
  public productId: number
  public value: string
  public productDiameter: number
  public productHeight: number
  public productWidth: number
  public productIsSpiked: number
  public productQualityId: number
  public productEanCode: string
  public productCondition: number
  public priority: number
  public valueType: string
  public valueCurrency: string
  public name: string
  public productClassId: number
  public startDate: string
  public expiryDate: number
  public productIsSpikeable: boolean
  public productIsRunflat: boolean
  public productIsCTyre: boolean
  public productIsEnforced: boolean
  public customerId: number
  public basePrice: number
  public basePriceListId: number
  public productVehicleTypes: Array<number>
  public valueAddonAmount: number
  public autoGenerateSource: string
  public bonus: number
  public isSupplierNetPrice: boolean
  public supplierProductGroupTag: string

  public constructor(data: Record<string, any>) {
    this.id = data.id
    this.type = data.type
    this.supplierId = data.supplierId
    this.shopId = data.shopId
    this.productTypeId = data.productTypeId
    this.productBrandId = data.productBrandId
    this.productRimBrandId = data.productRimBrandId
    this.productModelId = data.productModelId
    this.productId = data.productId
    this.value = data.value
    this.productDiameter = data.productDiameter
    this.productHeight = data.productHeight
    this.productWidth = data.productWidth
    this.productIsSpiked = data.productIsSpiked
    this.productQualityId = data.productQualityId
    this.productEanCode = data.productEanCode
    this.productCondition = data.productCondition
    this.priority = data.priority
    this.valueType = data.valueType
    this.valueCurrency = data.valueCurrency
    this.name = data.name
    this.productClassId = data.productClassId
    this.startDate = data.startDate
    this.expiryDate = data.expiryDate
    this.productIsSpikeable = data.productIsSpikeable
    this.productIsRunflat = data.productIsRunflat
    this.productIsCTyre = data.productIsCTyre
    this.productIsEnforced = data.productIsEnforced
    this.customerId = data.customerId
    this.basePrice = data.basePrice
    this.basePriceListId = data.basePriceListId
    this.productVehicleTypes = data.productVehicleTypes || []
    this.valueAddonAmount = data.valueAddonAmount
    this.autoGenerateSource = data.autoGenerateSource
    this.bonus = data.bonus
    this.isSupplierNetPrice = data.isSupplierNetPrice
    this.supplierProductGroupTag = data.supplierProductGroupTag
  }

  public isDiscount(): boolean {
    return this.type === MarginType.Discount
  }

  public isProfit(): boolean {
    return this.type === MarginType.Profit
  }

  public isCustomerDiscount(): boolean {
    return this.type === MarginType.CustomerDiscount
  }
}
