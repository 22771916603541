












import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Criteria from '@/models/price/manager/Criteria'

@Component({})
export default class EditSidebar extends Vue {
  @Prop({ required: true })
  private criteria: Array<Criteria>

  @Prop()
  private loading: boolean

  private toggle(c: Criteria) {
    if (this.loading) {
      return
    }
    c.selected = !c.selected
    if (c.selected) {
      if (c.key === 'productModelId') {
        for (let i = 0; i < this.criteria.length; i++) {
          if (this.criteria[i].key === 'productBrandId') {
            this.criteria[i].selected = true
            break
          }
        }
      }
    } else {
      if (c.key === 'productBrandId') {
        for (let i = 0; i < this.criteria.length; i++) {
          if (this.criteria[i].key === 'productModelId') {
            this.criteria[i].selected = false
            break
          }
        }
      }
    }
  }
}
