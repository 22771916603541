export default class BasePrice {
  public static CostPrice = 0
  public static ManufacturerListPrice = 1
  public static CalculatedCostPrice = 2
  // public static RelatedCustomerPrice = 3 // deprecated in price engine
  public static CostPriceWhenCustomerPrice = 4
  public static OtherPriceList = 5
  public static SupplierListPrice = 6
  public static ManufacturerThenSupplierListPrice = 7
}
