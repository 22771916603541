










































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Criteria from '@/models/price/manager/Criteria'
import Margin from '@/models/price/manager/Margin'
import ProductBrandField from '@/components/inputs/ProductBrandField.vue'
import ProductModelField from '@/components/inputs/ProductModelField.vue'

@Component({
  components: { ProductModelField, ProductBrandField },
})
export default class EditForm extends Vue {
  @Prop({ required: true })
  private criteria: Array<Criteria>

  @Prop({ required: true })
  private margin: Margin

  @Prop()
  private productTypes

  @Prop()
  private vehicleTypes

  @Prop()
  private suppliers

  @Prop()
  private productConditions

  private isCriteriaSelected(key: string): boolean {
    for (let i = 0; i < this.criteria.length; i++) {
      const c = this.criteria[i]
      if (c.key === key && c.selected) {
        return true
      }
    }
    return false
  }

  private isCriteriaGroupSelected(key: string): boolean {
    for (let i = 0; i < this.criteria.length; i++) {
      const c = this.criteria[i]
      if (c.group === key && c.selected) {
        return true
      }
    }
    return false
  }
}
