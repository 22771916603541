





















import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class ProductBrandField extends Vue {
  @Prop()
  private label: string

  @Prop({ type: Boolean, default: false })
  private dense: boolean

  @Prop({ type: Boolean, default: false })
  private outlined: boolean

  @Prop({ type: Number, default: null })
  private value: number // the brand ID (received from parent component)

  @Prop({ type: Boolean, default: null })
  private isRim?: boolean // check if product is rim

  private state = null // the brand object from backend (which id is emitted to parent on change)
  private sync = null // the query string

  private data = []
  private isLoadingSearch = false
  private isLoadingLookup = false
  private debounceTimer = null

  private created() {
    this.onValueChange()
  }

  // When external value (brand ID) changes, update our internal brand object
  @Watch('value')
  private onValueChange() {
    if (!this.value && !this.state) {
      return
    }
    if (this.value && this.state && this.value === this.state.id) {
      return
    }
    this.lookup(this.value, () => {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === this.value) {
          this.state = this.data[i]
          return
        }
      }
    })
  }

  // When autocomplete asks for value, provide the internal brand object
  private get input() {
    return this.state
  }

  // When autocomplete sets a new value, send the value to external parent component
  private set input(value) {
    this.$emit('input', value.id)
  }

  private get displayLabel() {
    return this.$t(this.label || 'Brand')
  }

  private get items() {
    return this.data
  }

  @Watch('sync')
  private onSearch() {
    clearTimeout(this.debounceTimer)
    this.debounceTimer = setTimeout(() => {
      this.doSearch()
    }, 400)
  }

  private doSearch() {
    this.isLoadingSearch = true
    if (this.sync != null && this.sync.trim().length > 1) {
      let params = '?query=' + encodeURIComponent(this.sync)
      if (this.isRim !== null && this.isRim !== undefined) {
        params += '&isRim=' + (this.isRim ? '1' : '0')
      }
      if (this.value) {
        params += '&forceIncludeId=' + encodeURIComponent(this.value)
      }
      this.$axios
        .get('/v4/site/products/brands/search' + params)
        .then((response) => {
          this.data = response.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error searching brands')
        })
        .finally(() => {
          this.isLoadingSearch = false
        })
    } else {
      this.data = []
      this.isLoadingSearch = false
    }
  }

  private lookup(id, cb) {
    this.isLoadingLookup = true
    this.$axios
      .get('/v4/site/products/brands/search?id=' + encodeURIComponent(id))
      .then((response) => {
        this.data = response.data.data
        cb()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error fetching brand')
      })
      .finally(() => {
        this.isLoadingLookup = false
      })
  }

  private filterItems(item, _queryText, _itemText) {
    // do not filter items, as we do that in backend
    return item
  }
}
