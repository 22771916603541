














































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Criteria from '@/models/price/manager/Criteria'
import Margin from '@/models/price/manager/Margin'
import BasePrice from '@/models/price/manager/BasePrice'
import MarginType from '@/models/price/manager/MarginType'
import ValueType from '@/models/price/manager/ValueType'

@Component({})
export default class EditFormCalculation extends Vue {
  @Prop({ required: true })
  private criteria: Array<Criteria>

  @Prop({ required: true })
  private margin: Margin

  @Prop()
  private priceLists

  @Prop({ type: Boolean })
  private canUsePriceBonus: boolean

  // todo: various translations, unified context

  private get valueTypes() {
    if (!this.margin) {
      return []
    }
    return [
      {
        id: ValueType.Percent,
        label: this.getValueTypeDescription(this.margin.type, ValueType.Percent, this.margin.basePrice)
      },
      {
        id: ValueType.Amount,
        label: this.getValueTypeDescription(this.margin.type, ValueType.Amount, this.margin.basePrice)
      },
      {
        id: ValueType.Static,
        label: this.getValueTypeDescription(this.margin.type, ValueType.Static, this.margin.basePrice)
      },
    ]
  }

  private get amountPrefix() {
    return this.getAmountSign(this.margin.type, this.margin.valueType, this.margin.basePrice)
  }

  private getValueTypeDescription(marginType, valueType, basePrice) {
    let prefix = ''
    switch (valueType) {
      case ValueType.Static:
        if (marginType === MarginType.Discount) {
          return 'Fixed cost price'
        } else {
          return 'Fixed sales price'
        }
      case ValueType.Percent:
        prefix = 'Percentage'
        break
      case ValueType.Amount:
        prefix = 'Fixed'
        break
      default:
        console.error('getValueTypeDescription: unknown valueType: ' + valueType)
        return '?'
    }
    let text = ''
    const sign = this.getAmountSign(marginType, valueType, basePrice)
    switch (sign) {
      case '-':
        text = prefix + ' reduction'
        break
      case '+':
        text = prefix + ' increment'
        break
      default:
        console.error('getValueTypeDescription: unknown amountSign: ' + sign)
        return '?'
    }
    return this.$t('c:price-margin-value-type:' + text)
  }

  private getAmountSign(marginType, valueType, basePrice) {
    if (!valueType || !this.margin || basePrice === null || basePrice === undefined) {
      console.error(
        'getAmountSign: missing valueType=' +
          valueType +
          ' or basePrice=' +
          basePrice +
          ' or margin=' +
          (this.margin ? 'defined' : 'undefined'),
      )
      return ''
    }
    if (valueType === ValueType.Static) {
      return '='
    }
    if (basePrice === BasePrice.ManufacturerListPrice) {
      return '-'
    }
    if (basePrice === BasePrice.SupplierListPrice) {
      return '-'
    }
    if (basePrice === BasePrice.ManufacturerThenSupplierListPrice) {
      return '-'
    }
    if (basePrice === BasePrice.OtherPriceList) {
      return '-'
    }
    if (basePrice === BasePrice.CostPriceWhenCustomerPrice) {
      return '+'
    }
    switch (marginType) {
      case MarginType.Discount:
      case MarginType.Default:
      case MarginType.CustomerDiscount:
        return '-'
      case MarginType.Profit:
        return '+'
    }
    console.error('getAmountSign: unknown marginType: ' + marginType)
    return '?'
  }

  private get amountSuffix() {
    if (!this.margin) {
      return ''
    }
    if (this.margin.valueType === ValueType.Percent) {
      return '%'
    } else {
      return this.margin.valueCurrency
    }
  }

  private get addonAmountSuffix() {
    if (!this.margin) {
      return ''
    }
    return this.margin.valueCurrency
  }

  private get basePriceOptions() {
    if (!this.margin) {
      return []
    }
    const prefix = 'c:base-price:'
    let costPriceLabel = ''
    switch (this.margin.type) {
      case MarginType.Discount:
        costPriceLabel = 'Supplier price'
        break
      case MarginType.Default:
      case MarginType.CustomerDiscount:
        costPriceLabel = 'Your default sales price'
        break
      case MarginType.Profit:
        costPriceLabel = 'Cost price'
        break
    }
    const result = [
      { id: BasePrice.ManufacturerListPrice, label: this.$t(prefix + 'Manufacturer list price') },
      { id: BasePrice.CostPrice, label: this.$t(prefix + costPriceLabel) }, // not really always cost, rather "the nearest price to calc from"
    ]
    if (this.margin.isProfit()) {
      result.push({ id: BasePrice.CalculatedCostPrice, label: this.$t(prefix + 'Calculated cost price') })
    }
    if (this.margin.isCustomerDiscount()) {
      result.push({ id: BasePrice.CostPriceWhenCustomerPrice, label: this.$t(prefix + 'Real cost price') })
    }
    if (!this.margin.isDiscount()) {
      result.push({
        id: BasePrice.SupplierListPrice,
        label: this.$t(prefix + 'Supplier list prices'),
      })
      result.push({
        id: BasePrice.ManufacturerThenSupplierListPrice,
        label: this.$t(prefix + 'Manufacturer then supplier list prices'),
      })
    }
    result.push({ id: BasePrice.OtherPriceList, label: this.$t(prefix + 'Other price list') })
    return result
  }

  private get showSourcePriceList() {
    return this.margin?.basePrice === BasePrice.OtherPriceList
  }

  private get showAddonAmount() {
    return this.margin && !this.margin?.isDiscount() && this.margin.valueType === ValueType.Percent
  }

  private get showCalculateFrom() {
    return this.margin && !this.margin.isDiscount()
  }

  private get showBonusAmount() {
    return this.margin?.isProfit() && this.canUsePriceBonus
  }
}
